//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { _getequipmentList, _getequipmentDel } from "@/api/province.js";
import ChangeApplication from "./ChangeApplication.vue";
import { _getcode } from "@/api/organizational";
export default {
  components: {
    ChangeApplication,
  },
  data() {
    return {
      pageSize: 10, //没页几条
      current: 1, //当前页数
      total: 0, //总条数
      formInline: {
        name: "",
        type: "",
      },
      value: "",
      input: "",
      tableData: [],
    };
  },
  created() {
    this.getequipmentList({ current: this.current, page: this.pageSize });
  },
  methods: {
    tableHeaderColor({ row, rowIndex, column, columnIndex }) {
      if (rowIndex === 0) {
        return "background-color:#e8f4ff;font-weight: 400;border-radius: 4px;color: #5f8bcf;height:48px;font-size:15px;font-family: Microsoft YaHei, Microsoft YaHei-Regular;padding:5px 0";
      }
    },
    // 列表数据接口
    getequipmentList(from) {
      _getequipmentList(from).then((res) => {
        this.tableData = res.data.data;
        this.total = res.data.total;
      });
    },
    // 新增的事件
    confirm() {
      this.$refs.changeApplication.open("新增");
    },
    // 修改的事件
    handleClick(row) {
      this.$refs.changeApplication.open(
        "修改",
        JSON.parse(JSON.stringify(row))
      );
    },
    deletebtn(row) {
      this.$confirm("是否确认删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        _getequipmentDel({ id: row.id }).then((res) => {
          if (res.code == "1") {
            this.$message(res.msg);
            this.getequipmentList({});
          }
        });
      });
    },
    // 查询
    querys(){
       this.getequipmentList(
        this.formInline,
       );
    },
    // 回调
    query() {
       this.getequipmentList(
        {...this.formInline,
         current: this.current,
        page: this.pageSize,
        }
       );
    },
    // 分页
    handleSizeChange(val) {
      this.current = 1;
      this.pageSize = val;
      let from = {
        ...this.formInline,
        current: this.current,
        page: this.pageSize,
      };
      this.getequipmentList(from);
    },
    handleCurrentChange(val) {
      this.current = val;
      let from = {
        ...this.formInline,
        current: this.current,
        page: this.pageSize,
      };
      this.getequipmentList(from);
    },
  },
};
