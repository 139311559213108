//消息模块
import { request, requestFd } from '../core/request'

// 消息
export function _getshow(params) {
  console.log('消息')
  return request({
    url:'/ananops/api/v1/pri/common/message/show',
      method: 'post',
      data: params,
      showLoading: 'true',
      tokenStatus: true // 是否加token
  })
}
// 删除
export function _getdelete(params) {
  console.log('删除')
  return request({
    url:'/ananops/api/v1/pri/common/message/delete',
      method: 'post',
      data: params,
      showLoading: 'true',
      tokenStatus: true // 是否加token
  })
}
// 消息详细内容数据接口
export function _showInfo(params) {
  console.log('消息详细内容数据接口')
  return request({
    url:'/ananops/api/v1/pri/common/message/showInfo',
      method: 'post',
      data: params,
      showLoading: 'true',
      tokenStatus: true // 是否加token
  })
}


