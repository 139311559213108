//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { _getshow, _getdelete, _showInfo } from "@/api/message.js";
export default {
  // watch: {
  //   filterText(val) {
  //     this.filetersdata(val);
  //   },
  // },
  data() {
    return {
      // filterText: "",
      selectIndex: "", //点击的li
      pageSize: 10, //没页几条
      current: 1, //当前页数
      total: 0, //总条数
      lists: [], //请求回来的数据
      newitem: {}, //点击拿到的每个数据
      newDate: [], //模糊匹配需要的数据
      routerArr: [], //路由数据
    };
  },
  created() {
    this.getshow({ current: this.current, page: this.pageSize });
  },
  methods: {
    getshow(from) {
      _getshow(from).then((res) => {
        this.lists = res.data.data;
        this.newDate = res.data.data;
        this.total = res.data.total;
      });
    },
    // 每个信息的点击事件
    information(item, index) {
      this.selectIndex = index + 1;
      this.lists[index].complteStatus =
        this.lists[index].complteStatus == "0" ? "1" : "1";
      _showInfo({ id: item.id }).then((res) => {
        this.newitem = res.data;
      });
    },
    // 模糊查询
    filetersdata(val) {
      this.lists = this.newDate;
      if (!val) {
        this.lists = this.newDate;
      } else {
        let newListData = [];
        this.lists.filter((item) => {
          if ((this.lists = item.title.indexOf(val) !== -1)) {
            newListData.push(item);
          }
        });
        this.lists = newListData;
      }
    },
    // 关闭的界面
    down() {
      this.selectIndex = "";
      this.newitem = {};
      this.getshow({ current: this.current, page: this.pageSize });
    },
    // 前往处理的事件
    goTo() {
      let s = ""
      let neWurl = ""
      this.routerArr = JSON.parse(localStorage.getItem("returnA"));
      if(this.newitem.skipType == "0"){
        s = this.newitem.url
        neWurl = this.newitem.url
      }else if(this.newitem.skipType == "1"){
        neWurl = this.newitem.url.split("/").slice(0,-1).join("/")
         s = `${this.newitem.url}?type=1&id=${this.newitem.skipId}&oridStatus=${this.newitem.statusCode}`;
      }else if(this.newitem.skipType == "2"){
        neWurl = this.newitem.url.split("/").slice(0,-1).join("/")
         s = `${this.newitem.url}?type=${this.newitem.orderStatus}&orderId=${this.newitem.skipId}&spDid=${this.newitem.spDid}`;
      }else if(this.newitem.skipType == "3"){//报障
          neWurl = this.newitem.url.split("/").slice(0,-1).join("/")
         s = `${this.newitem.url}?type=${this.newitem.orderStatus}&id=${this.newitem.skipId}`;
      }
      this.routerArr.filter((item, index) => {
        if (item.children.length != 0) {
          item.children.filter((iovs) => {
            if (neWurl == iovs.path) { 
              sessionStorage.setItem("active", JSON.stringify(item.active));
              sessionStorage.setItem("dataList", JSON.stringify(item));
              sessionStorage.setItem("selectIndex", JSON.stringify(index + 1));
              this.$router.push(s)
            }
          });
        }
      });
    },
    // 删除的事件
    deletebtn(id) {
      _getdelete({ id: id }).then((res) => {
        if (res.code == 1) {
          this.$message(res.msg);
          this.down();
        }
      });
    },
    handleCurrentChange(val) {
      this.current = val;
      let from = {
        current: this.current,
        page: this.pageSize,
      };
      this.getshow(from);
    },
  },
};
