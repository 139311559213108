//获取省市区三级联动数据
import { request, requestFd } from '../core/request'

// 获取省的数据
export function _getProvinceList (params) {
  console.log('获取省的数据')
  return request({
    url: '/ananops/api/v1/pub/region/provincelist',
    method: 'get',
    params: params,
    showLoading: 'true',
    tokenStatus: true // 是否加token
  })
}

// 获取市的数据
export function _getProvinceCityList (params) {
  console.log('获取市的数据')
  return request({
    url: '/ananops/api/v1/pub/region/provinceCitylist',
    method: 'get',
    params: params,
    showLoading: 'true',
    tokenStatus: true // 是否加token
  })
}

// 获取区的数据
export function _getCityCountyList (params) {
  console.log('获取区的数据')
  return request({
    url: '/ananops/api/v1/pub/region/cityCountylist',
    method: 'get',
    params: params,
    showLoading: 'true',
    tokenStatus: true // 是否加token
  })
}

// 完善企业信息
export function _getSubmitUser (params) {
  console.log('完善企业信息')
  return request({
    url: '/ananops/api/v1/pri/account/perfect',
    method: 'post',
    data: params,
    showLoading: 'true',
    tokenStatus: true // 是否加token
  })
}

// 企业信息回显
export function _getgetinfo (params) {
  console.log('企业信息回显')
  return request({
    url: '/ananops/api/v1/pri/account/getinfo',
    method: 'post',
    data: params,
    showLoading: 'true',
    tokenStatus: true // 是否加token
  })
}

// 企业信息回显
export function _getgetinfoById (params) {
  console.log('企业信息回显')
  return request({
    url: '/ananops/api/v1/pri/account/getinfoById',
    method: 'post',
    data: params,
    showLoading: 'true',
    tokenStatus: true // 是否加token
  })
}
// 照片回显
export function _download (params) {
  console.log('企业信息回显')
  return request({
    url: '/ananops/api/v1/pub/file/download',
    method: 'get',
    params: params,
    showLoading: 'true',
    tokenStatus: true // 是否加token
  })
}

// 备品备件列表
export function _getequipmentList (params) {
  console.log('备品备件列表')
  return request({
    url: '/ananops/api/vi/pri/equipment/equipmentList',
    method: 'post',
    data: params,
    showLoading: 'true',
    tokenStatus: true // 是否加token
  })
}

// 增加设备备件
export function _getequipmentAdd (params) {
  console.log('增加设备备件')
  return request({
    url: '/ananops/api/vi/pri/equipment/equipmentAdd',
    method: 'post',
    data: params,
    showLoading: 'true',
    tokenStatus: true // 是否加token
  })
}


//修改设备备件
export function _getequipmentEdit (params) {
  console.log('修改设备备件')
  return request({
    url: '/ananops/api/vi/pri/equipment/equipmentEdit',
    method: 'post',
    data: params,
    showLoading: 'true',
    tokenStatus: true // 是否加token
  })
}

//删除设备备件
export function _getequipmentDel (params) {
  console.log('删除设备备件')
  return request({
    url: '/ananops/api/vi/pri/equipment/equipmentDel',
    method: 'post',
    data: params,
    showLoading: 'true',
    tokenStatus: true // 是否加token
  })
}
//
export function _getUniqueName (params) {
  console.log('查询企业名称是否重复')
  return request({
    url: '/ananops/api/v1/pri/account/getUniqueName',
    method: 'get',
    params: params,
    showLoading: 'true',
    tokenStatus: true // 是否加token
  })
}

//提交星级评定
export function _getStarRate (params) {
  console.log('提交星级评定')
  return request({
    url: '/ananops/api/v1/pri/account/starRate',
    method: 'post',
    data: params,
    showLoading: 'true',
    tokenStatus: true // 是否加token
  })
}
//修改星级评定
export function _getStarRateRevise (params) {
  console.log('修改星级评定')
  return request({
    url: '/ananops/api/v1/pri/account/starRateRevise',
    method: 'post',
    data: params,
    showLoading: 'true',
    tokenStatus: true // 是否加token
  })
}
//删除星级评定
export function _getstarRateDelete (params) {
  console.log('删除星级评定')
  return request({
    url: '/ananops/api/v1/pri/account/starRateDelete',
    method: 'post',
    data: params,
    showLoading: 'true',
    tokenStatus: true // 是否加token
  })
}


//下载附件
export function _getStarRateDownload (params) {
  console.log('下载')
  return request({
    url: '/ananops/api/v1/pub/file/download',
    method: 'get',
    params: params,
    showLoading: 'true',
    tokenStatus: true // 是否加token
  })
}


//星级评定回显
export function _getStarRatePlatformStarRateInfo (params) {
  console.log('星级评定回显')
  return request({
    url: '/ananops/api/v1/pri/account/platformStarRateInfo',
    method: 'post',
    data: params,
    showLoading: 'true',
    tokenStatus: true // 是否加token
  })
}


//星级评定列表回显
export function _getStarRatePlatformStarRateListByPage (params) {
  console.log('星级评定回显')
  return request({
    url: '/ananops/api/v1/pri/account/platformStarRateListByPage',
    method: 'post',
    data: params,
    showLoading: 'true',
    tokenStatus: true // 是否加token
  })
}



//星级评定平台审核
export function _getStarRatePlatformStarRate (params) {
  console.log('星级评定平台审核')
  return request({
    url: '/ananops/api/v1/pri/account/platformStarRate',
    method: 'post',
    data: params,
    showLoading: 'true',
    tokenStatus: true // 是否加token
  })
}


// 星级年份判断
export function _getSubmitYearCheck (params) {
  console.log('星级年份判断')
  return request({
    url: '/ananops/api/v1/pri/account/submitYearCheck',
    method: 'post',
    data: params,
    showLoading: 'true',
    tokenStatus: true // 是否加token
  })
}