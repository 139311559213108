import { request, requestFd } from '../core/request'


// 获取绑定的服务商list
export function _getBindInfo(params) {
    console.log('获取绑定的服务商list')
    return request({
        url:'/ananops/api/v1/pri/bindsp/getBindInfo',
        method: 'post',
        data: params,
        showLoading: 'true',
        tokenStatus: 'false' // 是否加token
    })
}


// 获取常用验收人和审批人
export function _getConfigPeople(params) {
    console.log('获取绑定的服务商list')
    return request({
        url:'/ananops/api/v1/pri/report/getConfigPeople',
        method: 'post',
        data: params,
        showLoading: 'true',
        tokenStatus: 'false' // 是否加token
    })
}

// 需求方提交报障
export function _getapply(params) {
    console.log('需求方提交报障')
    return request({
        url:'/ananops/api/v1/pri/report/apply',
        method: 'post',
        data: params,
        showLoading: 'true',
        tokenStatus: 'false' // 是否加token
    })
}
// 报障工单列表
export function _getorderinfo(params) {
    console.log('报障工单列表')
    return request({
        url:'/ananops/api/v1/pri/report/orderInfoByPage',
        method: 'post',
        data: params,
        showLoading: 'true',
        tokenStatus: 'false' // 是否加token
    })
}
// 保障审批
export function _getapplyconfirm(params) {
    console.log('保障审批')
    return request({
        url:'/ananops/api/v1/pri/report/applyconfirm',
        method: 'post',
        data: params,
        showLoading: 'true',
        tokenStatus: 'false' // 是否加token
    })
}
// 派遣工程师
export function _getassign(params) {
    console.log('派遣工程师')
    return request({
        url:'/ananops/api/v1/pri/report/assign',
        method: 'post',
        data: params,
        showLoading: 'true',
        tokenStatus: 'false' // 是否加token
    })
}


// 确认指派合并接口
export function _getspconfirmAndAssign(params) {
  console.log('确认指派合并接口')
  return request({
      url:'/ananops/api/v1/pri/report/spconfirmAndAssign',
      method: 'post',
      data: params,
      showLoading: 'true',
      tokenStatus: 'false' // 是否加token
  })
}

// 报障验收
export function _getcheck(params) {
    console.log('报障验收')
    return request({
        url:'/ananops/api/v1/pri/report/check',
        method: 'post',
        data: params,
        showLoading: 'true',
        tokenStatus: 'false' // 是否加token
    })
}
// 工程师完成维修
export function _getcomplete(params) {
    console.log('工程师完成维修')
    return request({
        url:'/ananops/api/v1/pri/report/spcomplete',
        method: 'post',
        data: params,
        showLoading: 'true',
        tokenStatus: 'false' // 是否加token
    })
}

// 工程师签到
export function _getsign(params) {
    console.log('工程师签到')
    return request({
        url:'/ananops/api/v1/pri/report/sign',
        method: 'post',
        data: params,
        showLoading: 'true',
        tokenStatus: 'false' // 是否加token
    })
}
// 服务商确认
export function _getspconfirm(params) {
    console.log('服务商确认')
    return request({
        url:'/ananops/api/v1/pri/report/spconfirm',
        method: 'post',
        data: params,
        showLoading: 'true',
        tokenStatus: 'false' // 是否加token
    })
}
// 工程师接单
export function _gettakeorder(params) {
    console.log('工程师接单')
    return request({
        url:'/ananops/api/v1/pri/report/takeorder',
        method: 'post',
        data: params,
        showLoading: 'true',
        tokenStatus: 'false' // 是否加token
    })
}
// 工程师
export function _getworkerInfo(params) {
    console.log('工程师')
    return request({
        url:'/ananops/api/v1/pri/report/workerInfo',
        method: 'get',
        params: params,
        showLoading: 'true',
        tokenStatus: 'false' // 是否加token
    })
}

// 转派工程师
export function _getworkerInfoChange(params) {
  console.log('转派工程师')
  return request({
      url:'/ananops/api/v1/pri/report/otherEngineer',
      method: 'get',
      params: params,
      showLoading: 'true',
      tokenStatus: 'false' // 是否加token
  })
}

// 报障申请设备列表
export function _getequipmentReportList(params) {
    console.log('报障申请设备列表')
    return request({
        url:'/ananops/api/vi/pri/equipment/equipmentReportList',
        method: 'post',
        data: params,
        showLoading: 'true',
        tokenStatus: 'false' // 是否加token
    })
}
// 维保工单号模糊查询
export function _getmtorderSearch(params) {
    console.log('维保工单号模糊查询')
    return request({
        url:'/ananops/api/vi/pri/equipment/mtorderSearch',
        method: 'post',
        data: params,
        showLoading: 'true',
        tokenStatus: 'false' // 是否加token
    })
}
// 报障工单号模糊查询
export function _getorderSearch(params) {
    console.log('报障工单号模糊查询')
    return request({
        url:'/ananops/api/vi/pri/equipment/orderSearch',
        method: 'post',
        data: params,
        showLoading: 'true',
        tokenStatus: 'false' // 是否加token
    })
}

// 设备名称模糊查询
export function _getequipmentSearch(params) {
    console.log('设备名称模糊查询')
    return request({
        url:'/ananops/api/vi/pri/equipment/equipmentSearch',
        method: 'post',
        data: params,
        showLoading: 'true',
        tokenStatus: 'false' // 是否加token
    })
}
// 设备名称change查询
export function _getequipmentSearchId(params) {
    console.log('设备名称change查询')
    return request({
        url:'/ananops/api/vi/pri/equipment/equipmentSearchId',
        method: 'post',
        data: params,
        showLoading: 'true',
        tokenStatus: 'false' // 是否加token
    })
}
// 报障设备申请新增
export function _getequipmentOrder(params) {
    console.log('报障设备申请新增')
    return request({
        url:'/ananops/api/vi/pri/equipment/equipmentOrder',
        method: 'post',
        data: params,
        showLoading: 'true',
        tokenStatus: 'false' // 是否加token
    })
}

// 报障设备申请审批
export function _getequipmentApproval(params) {
    console.log('报障设备申请审批')
    return request({
        url:'/ananops/api/vi/pri/equipment/equipmentApproval',
        method: 'post',
        data: params,
        showLoading: 'true',
        tokenStatus: 'false' // 是否加token
    })
}
// 报障设备申请修改
export function _getequipmentOrderEdit(params) {
    console.log('报障设备申请修改')
    return request({
        url:'/ananops/api/vi/pri/equipment/equipmentOrderEdit',
        method: 'post',
        data: params,
        showLoading: 'true',
        tokenStatus: 'false' // 是否加token
    })
}
// 历史记录
export function _equipmentApplyHistroy(params) {
    console.log('历史记录')
    return request({
        url:'/ananops/api/vi/pri/equipment/equipmentApplyHistroy',
        method: 'post',
        data: params,
        showLoading: 'true',
        tokenStatus: 'false' // 是否加token
    })
}
// 报障设备申请删除
export function _getequipmentOrderDelete(params) {
    console.log('报障设备申请删除')
    return request({
        url:'/ananops/api/vi/pri/equipment/equipmentOrderDelete',
        method: 'post',
        data: params,
        showLoading: 'true',
        tokenStatus: 'false' // 是否加token
    })
}

// 报障申请 故障部门的数据
export function _getselectUserBindDeptList(params) {
    console.log('报障设备申请审批')
    return request({
        url:'/ananops/api/v1/pri/report/selectUserBindDeptList',
        method: 'get',
        params: params,
        showLoading: 'true',
        tokenStatus: 'false' // 是否加token
    })
}
// 报障默认回显审批人、验收人、联系人、联系人电话
export function _getshowBZPerson(params) {
    console.log('报障默认回显审批人、验收人、联系人、联系人电话')
    return request({
        url:'/ananops/api/v1/pri/report/showBZPerson',
        method: 'get',
        params: params,
        showLoading: 'true',
        tokenStatus: 'false' // 是否加token
    })
}
// 报障默认回显审批人、验收人、联系人、联系人电话
export function _getgetConfirms(params) {
    console.log('报障默认回显审批人、验收人、联系人、联系人电话')
    return request({
        url:'/ananops/api/v1/pri/report/getConfirms',
        method: 'get',
        params: params,
        showLoading: 'true',
        tokenStatus: 'false' // 是否加token
    })
}
// 
export function _getgetCheckers(params) {
    return request({
        url:'/ananops/api/v1/pri/report/getCheckers',
        method: 'get',
        params: params,
        showLoading: 'true',
        tokenStatus: 'false' // 是否加token
    })
}
// 获取上级审批人
export function _getSupConfim(params) {
    console.log('获取上级审批人')
    return request({
        url:'/ananops/api/v1/pri/report/getSupConfim',
        method: 'get',
        params: params,
        showLoading: 'true',
        tokenStatus: 'false' // 是否加token
    })
}
// table下有几条数据
export function _getorderNumber(params) {
    console.log('table下有几条数据')
    return request({
        url:'/ananops/api/v1/pri/report/orderNumber',
        method: 'get',
        params: params,
        showLoading: 'true',
        tokenStatus: 'false' // 是否加token
    })
}
// 报障申请
export function _spapply(params) {
    console.log('报障申请')
    return request({
        url:'/ananops/api/v1/pri/report/spapply',
        method: 'post',
        data: params,
        showLoading: 'true',
        tokenStatus: 'false' // 是否加token
    })
}

// 获取子单位部门
export function _getCorrentDepts(params) {
    console.log('获取子单位部门')
    return request({
        url:'/ananops/api/v1/pri/report/getCorrentDepts',
        method: 'post',
        data: params,
        showLoading: 'true',
        tokenStatus: 'false' // 是否加token
    })
}

// 获取需求方验收人
export function _getCheckers(params) {
    console.log('获取需求方验收人')
    return request({
        url:'/ananops/api/v1/pri/report/getCheckers',
        method: 'get',
        params: params,
        showLoading: 'true',
        tokenStatus: 'false' // 是否加token
    })
}