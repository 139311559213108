//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { _getorderSearch, _getequipmentSearch } from "@/api/disabilities.js";
import { _getequipmentAdd, _getequipmentEdit } from "@/api/province.js";

export default {
  data() {
    return {
      title: "",
      dialogVisible: false,
      optionsName: [],
      btnDisab:false,
      options:[
        {name:"有",id:"1"},
        {name:"无",id:"0"},
      ],
      form: {
        dsDid: "",
        name: "",
        type: "",
        firm: "",
        price: "",
        stockStatus: "",
        registerTime: "",
        opComment: "",
      },
       rules:{
         name: [
           { required: true, message: '请输入设备名称', trigger: 'blur' }
          ],
          
           type: [
            { required: true, message: '请输入设备型号', trigger: 'blur' }
          ],
           firm: [
            { required: true, message: '请输入设备厂商', trigger: 'blur' }
          ],
           price: [
            { required: true, message: '请输入设备金额', trigger: 'blur' },
          ],
           stockStatus: [
            { required: true, message: '请选择库存状态' ,trigger: 'change'},
          ],
           registerTime: [
            { required: true, message: '请选择登记时间' ,trigger: 'change'},
          ],
      }
    };
  },
  methods: {
    open(title, data) {
      this.dialogVisible = true;
      if (title == "修改") {
        this.form = data;
        this.title = title;
      } else {
        this.title = title;
        this.form = {
          dsDid: "",
          name: "",
          type: "",
          firm: "",
          price: "",
          stockStatus: "",
          registerTime: "",
          opComment: "",
        };
      }
    },

    // 提交
    onSubmit() {
      this.btnDisab = true
       this.$refs["form"].validate((valid) => {
         if (valid) {
           this.form.price = parseFloat(this.form.price)
            if (this.title == "修改") {
            _getequipmentEdit(this.form).then((res) => {
              if(res.code == "1"){
                this.btnDisab = false
                this.$emit("query");
                this.dialogVisible = false;
              }else{
                this.btnDisab = false
              }
            }).catch(() =>{
                this.btnDisab = false
            });
          } else {
            _getequipmentAdd(this.form).then((res) => {
              if(res.code == "1"){
              this.dialogVisible = false;
              this.$emit("query");
              this.btnDisab = false
              }else{
                this.btnDisab = false
              }
            }).catch(()=>{
              this.dialogVisible = false;
            });
          }
            }
       })
     
    },
    close() {
      this.form = {
          dsDid: "",
          name: "",
          type: "",
          firm: "",
          price: "",
          stockStatus: "",
          registerTime: "",
          opComment: "",
        };
      this.$refs["form"].resetFields();

    },
  },
};
